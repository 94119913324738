import React from 'react'
import { PageProps } from 'gatsby'
import { Footer, Head } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import img from '~/assets/img/img-competencias.png'
import cn from 'classnames'

const Competencias = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>Competências Unicred</h1>
          {/* <p>Competências Corporativas, Humanas, Funcionais, Apoio e Linha</p> */}
        </section>
        <div className={cn(st.imgText, st.orange)}>
          <img src={img} alt="" />
          <div>
            <p>
              <strong>
                O posicionamento premium só{' '}
                <span className="block">ocorre por meio das pessoas e da</span>
                transformação de competências{' '}
                <span className="block">em atitudes.</span>
              </strong>
            </p>
            <p>
              Contamos com sua cooperação para a transformação da nossa cultura
              por meio do fortalecimento das nossas competências!
            </p>
          </div>
        </div>
        <section className={cn('container', st.fontCustom)}>
          <h2>Quais as competências da Unicred Conexão?</h2>
          <p>
            <strong>Tudo começou com as competências corporativas</strong>
          </p>
          <p>Elas são as nossas competências essenciais</p>
          <ul className={cn(st.skillsBox, st.oneBox)}>
            <li>
              <section>
                <h3 className={st.colorGreen}>Orientação para o cooperado</h3>
                <p>
                  Entendimento que o cooperado é o principal ativo da
                  Cooperativa.
                </p>
              </section>
            </li>
            <li>
              <section>
                <h3 className={st.colorOrange}>
                  Foco no desenvolvimento socioeconômico
                </h3>
                <p>
                  Gestão pautada nos princípios cooperativistas e geração de
                  prosperidade.
                </p>
              </section>
            </li>
            <li>
              <section>
                <h3 className={st.colorPurple}>Evolução contínua</h3>
                <p>
                  Ações tecnológicas, processuais e de capital humano, visando o
                  crescimento e sustentabilidade da Unicred.
                </p>
              </section>
            </li>
          </ul>
        </section>
        <section className="container no-padding-top">
          <h2>Competências humanas</h2>
          <ul className={cn(st.skillsBox, st.twoBox)}>
            <li>
              <section>
                <h3 className={st.colorGreen}>FLEXIBILIDADE COGNITIVA</h3>
                <p>
                  Refere-se a capacidade de propor novas soluções que
                  ultrapassam a zona de conforto.
                </p>
              </section>
            </li>
            <li>
              <section>
                <h3 className={st.colorOrange}>PENSAMENTO CRÍTICO</h3>
                <p>
                  Refere-se a nossa capacidade de raciocinar e não ser apenas um
                  receptor passivo de informações.
                </p>
              </section>
            </li>
            <li>
              <section>
                <h3 className={st.colorGreenLight}>ORIENTAÇÃO PARA SERVIR</h3>
                <p>Refere-se a capacidade de pensar e agir pelo bem comum</p>
              </section>
            </li>
            {/* <li>
              <section>
                <h3 className={st.colorPurple}>Protagonismo</h3>
                <p>
                  Ser protagonista é ser agente de transformação por meio do seu
                  autodesenvolvimento.
                </p>
              </section>
            </li>
            <li>
              <section>
                <h3 className={st.colorPink}>Melhora contínua</h3>
                <p>
                  Buscar a execução com excelência naquilo que seu cargo prevê,
                  visando melhorar sempre.
                </p>
              </section>
            </li> */}
          </ul>
        </section>
        {/* <section className="container no-padding-top no-padding-mobile">
          <h2>Competências funcionais</h2>
          <ul className={st.functionalSkills}>
            <li>
              <section>
                <div className={cn('icon-svg-arrow', st.colorGreenLight)}>
                  <h3 className={st.greenLight}>Diretores</h3>
                </div>
                <ul>
                  <li>
                    <section>
                      <h4>Visão Sistêmica com Ação Estratégica</h4>
                      <p>
                        Entender a Cooperativa em sua totalidade e de forma
                        integrada, traçando ações e planos de acordo com os
                        objetivos estratégicos da Unicred, atingindo os
                        resultados esperados pela instituição.
                      </p>
                    </section>
                  </li>
                  <li>
                    <section>
                      <h4>Liderança de Pessoas e Processos</h4>
                      <p>
                        Liderar e Influenciar positivamente as pessoas,
                        conectando a equipe ao propósito e à estratégia da
                        instituição, proporcionando desafios e oportunidades, em
                        consonância com princípios e objetivos da Unicred, bem
                        como gerenciar adequadamente os recursos e estabelecer
                        processos que garantam o alcance dos objetivos da
                        Cooperativa.
                      </p>
                    </section>
                  </li>
                </ul>
              </section>
            </li>
            <li>
              <section>
                <div className={cn('icon-svg-arrow', st.colorGreen)}>
                  <h3 className={st.green}>
                    Gerentes, coordenadores,{' '}
                    <span className="block">
                      supervisores e superintendentes
                    </span>
                  </h3>
                </div>
                <ul>
                  <li>
                    <section>
                      <h4>Visão e Gestão Estratégica</h4>
                      <p>
                        Promover o alinhamento estratégico organizacional,
                        definindo diretrizes, alavancando negócios, aplicando os
                        valores e patrocinando uma visão sustentável e de longo
                        prazo de prosperidade sem perdedores.
                      </p>
                    </section>
                  </li>
                  <li>
                    <section>
                      <h4>Liderança Inspiradora e Estratégica</h4>
                      <p>
                        Liderar de modo a conectar a equipe ao propósito e às
                        estratégias da instituição e de conduzir os
                        profissionais para o alcance do melhor resultado, bem
                        como estabelecer processos que garantam o alcance dos
                        objetivos da cooperativa
                      </p>
                    </section>
                  </li>
                </ul>
              </section>
            </li>
            <li>
              <section>
                <div className={cn('icon-svg-arrow', st.colorOrange)}>
                  <h3 className={st.orange}>
                    Especialistas, assessores,{' '}
                    <span className="block">
                      {' '}
                      gerentes de relacionamento e analistas
                    </span>
                  </h3>
                </div>
                <ul>
                  <li>
                    <section>
                      <h4>Visão Sistêmica com Senso Crítico</h4>
                      <p>
                        Visualiza e pensa na instituição como um todo, agindo em
                        sintonia com os interesses da Unicred, considerando a
                        implicação das suas ações em todas as outras áreas,
                        analisando criticamente os processos e situações.
                      </p>
                    </section>
                  </li>
                  <li>
                    <section>
                      <h4>Domínio Técnico com Senso de Customização</h4>
                      <p>
                        Ter domínio frente aos seus processos de trabalho,
                        focando e responsabilizando-se por entregas completas,
                        de qualidade e customizadas. Capacidade para criar e
                        aplicar conceitos e métodos de trabalho.
                      </p>
                    </section>
                  </li>
                </ul>
              </section>
            </li>
            <li>
              <section>
                <div className={cn('icon-svg-arrow', st.colorPurple)}>
                  <h3 className={st.purple}>Assistentes</h3>
                </div>
                <ul>
                  <li>
                    <section>
                      <h4>Responsabilidade com suas Entregas</h4>
                      <p>
                        Realizar as atividades de forma engajada e responsável,
                        tendo entendimento dos motivos e consequências de sua
                        atuação, focando sempre nos objetivos da Unicred.
                      </p>
                    </section>
                  </li>
                  <li>
                    <section>
                      <h4>Senso de Aprofundamento Técnico (Investigativo)</h4>
                      <p>
                        Buscar sempre expandir seus conhecimentos sobre negócios
                        e soluções e sobre a própria Unicred, bem como para a
                        realização de suas atividades.
                      </p>
                    </section>
                  </li>
                </ul>
              </section>
            </li>
          </ul>
        </section>
        <section className={cn('container no-padding-top', st.fontCustom)}>
          <h2>Cargos de apoio e linha</h2>
          <p>
            <strong>
              Nossa estrutura de carreira é composta de cargos de apoio e de
              linha:
            </strong>
          </p>
          <ul className={st.supportBox}>
            <li>
              <section>
                <div className={st.green}>
                  <h3>Competência de apoio</h3>
                  <p>
                    <strong>Foco na solução</strong>
                  </p>
                </div>
                <ul>
                  <li>
                    <strong>Compreende as áreas que criam e garantem</strong>{' '}
                    condições de pleno funcionamento da operação na ponta.
                  </li>
                  <li>
                    <strong>
                      São responsáveis pelo desenvolvimento de ações e projetos
                    </strong>{' '}
                    necessários para sustentação do negócio.
                  </li>
                  <li>
                    <strong>Planejam ações estratégicas</strong> que impactam no
                    atendimento ao Cooperado.
                  </li>
                  <li>
                    <strong>Atuam como parceiros de negócios.</strong>
                  </li>
                </ul>
              </section>
            </li>
            <li>
              <section>
                <div className={st.orange}>
                  <h3>Competência de linha</h3>
                  <p>
                    <strong>Foco no resultado</strong>
                  </p>
                </div>
                <ul>
                  <li>
                    <strong>Compreende as áreas que atuam</strong> diretamente
                    na geração de negócios na ponta.
                  </li>
                  <li>
                    <strong>São responsáveis pelo atendimento</strong> próximo e
                    diretamente envolvido com o Cooperado.
                  </li>
                  <li>
                    <strong>Suas ações impactam na experiência</strong> de
                    atendimento ao Cooperado.
                  </li>
                  <li>
                    Prestam consultoria no planejamento financeiro e nas
                    soluções personalizadas ao Cooperado.
                  </li>
                </ul>
              </section>
            </li>
          </ul>
        </section> */}
      </main>
      <Footer location={location} />
    </>
  )
}

export default Competencias
